import { gql, TypedDocumentNode } from '@apollo/client'

import { ProductCardFragment } from './ProductCardFragment.generated'
import { productLabelListFragment } from '@emico/product-label-fragment'

const productCardFragment = gql`
  fragment ProductCardFragment on ProductInterface {
    id
    uid
    sku
    name
    canonicalUrl
    urlKey
    onlyXLeftInStock
    stockStatus
    tweakwiseItemNumber
    ratingSummary
    reviewCount
    isMainProduct

    preorder {
      atpDate
      atpDateFormatted
      isPreorder
    }

    urlRewrites {
      url
      parameters {
        name
        value
      }
    }

    smallImage {
      url
      label
      disabled
    }

    highlightedAttributesPlp {
      attributeCode
      attributeLabel
      attributeValue
      attributePosition
    }

    priceRange {
      minimumPrice {
        discount {
          amountOff
          percentOff
        }

        regularPrice {
          currency
          value
        }

        finalPrice {
          currency
          value
        }
      }
    }

    categories {
      ... on CategoryTree {
        uid
        name
        urlKey
        breadcrumbs {
          categoryUid
          categoryName
          categoryUrlKey
          categoryUrlPath
        }
      }
    }

    # Custom Berg
    productLabels {
      ...ProductLabelListFragment
    }
  }

  ${productLabelListFragment}
` as TypedDocumentNode<ProductCardFragment>

export default productCardFragment
