import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import { minWidth } from '@emico/styles'
import { ButtonPrimary as BaseButton, Loader } from '@emico/ui'

import theme from '../theme'
import CraftLink from './CraftLink'
import NextLink from './NextLink'

export type ButtonColorType = 'brand' | 'neutral' | 'green'

const getBackgroundColor = (colorType?: ButtonColorType) => {
  switch (colorType) {
    case 'neutral':
      return theme.colors.secondary
    case 'green':
      return theme.colors.primaryGreen
    default:
      return theme.colors.primary
  }
}

const getBackgroundHoverColor = (colorType?: ButtonColorType) => {
  switch (colorType) {
    case 'neutral':
      return theme.colors.secondaryHover
    case 'green':
      return theme.colors.primaryGreenHover
    default:
      return theme.colors.primaryHover
  }
}

const buttonStylesHover = css`
  border-color: var(--button-hover-border, ${theme.colors.primaryHover});

  &::before {
    transform: scaleX(1);
    transform-origin: left;
  }
`

const buttonStyles = css`
  font-size: ${theme.fontSizes.md};
  line-height: ${theme.lineHeights.md};
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.fontWeights.medium};
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  position: relative;
  transition-property: ${theme.transition.properties.colors};
  transition-duration: ${theme.transition.durations.slow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};
  background-color: var(--button-background, ${theme.colors.primary});
  border: ${theme.borders.md} var(--button-border, ${theme.colors.primary});
  border-radius: ${theme.borderRadius.base};
  color: var(--button-on-background, ${theme.colors.textLight});

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(
      --button-hover-background,
      ${theme.colors.primaryHover}
    );
    transform: scaleX(0);
    transform-origin: right;
    transition-property: ${theme.transition.properties.common};
    transition-duration: ${theme.transition.durations.slow};
    transition-timing-function: ${theme.transition.timingFunctions
      .cubicBezierSmooth};
  }

  &:hover {
    background-color: var(--button-background, ${theme.colors.primary});
    color: var(
      --button-hover-on-background,
      var(--button-on-background, ${theme.colors.textLight})
    );
  }

  &:focus,
  &:active {
    box-shadow: none;
    outline: 1px solid currentColor;
    outline-offset: -4px;
  }

  @media ${minWidth('lg')} {
    &:hover {
      ${buttonStylesHover};
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    background-color: ${theme.colors.grayBrown};
    border-color: ${theme.colors.grayBrown};
  }
`

const StyledButton = styled(BaseButton, {
  shouldForwardProp: (prop) =>
    !['colorType', 'isIcon', 'isActive'].includes(prop.toString()),
})<{ colorType?: ButtonColorType; isIcon?: boolean; isActive?: boolean }>`
  ${buttonStyles};
  --button-background: ${({ colorType }) => getBackgroundColor(colorType)};
  --button-border: ${({ colorType }) => getBackgroundColor(colorType)};
  --button-hover-background: ${({ colorType }) =>
    getBackgroundHoverColor(colorType)};
  --button-hover-border: ${({ colorType }) =>
    getBackgroundHoverColor(colorType)};
  padding: ${({ isIcon }) => (isIcon ? '6px' : '13px 40px')};
  width: ${({ isIcon }) => (isIcon ? '42px' : 'auto')};
  aspect-ratio: ${({ isIcon }) => isIcon && '1 / 1'};

  ${({ isActive }) => isActive && buttonStylesHover};
`

const StyledNextLink = styled(NextLink, {
  shouldForwardProp: (prop) =>
    !['colorType', 'isIcon', 'isActive'].includes(prop.toString()),
})<{ colorType?: ButtonColorType; isIcon?: boolean; isActive?: boolean }>`
  ${buttonStyles};
  --button-background: ${({ colorType }) => getBackgroundColor(colorType)};
  --button-border: ${({ colorType }) => getBackgroundColor(colorType)};
  --button-hover-background: ${({ colorType }) =>
    getBackgroundHoverColor(colorType)};
  --button-hover-border: ${({ colorType }) =>
    getBackgroundHoverColor(colorType)};
  padding: ${({ isIcon }) => (isIcon ? '6px' : '13px 40px')};
  width: ${({ isIcon }) => (isIcon ? '42px' : 'auto')};
  aspect-ratio: ${({ isIcon }) => isIcon && '1 / 1'};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${({ isActive }) => isActive && buttonStylesHover}
`

const StyledCraftLink = styled(CraftLink, {
  shouldForwardProp: (prop) =>
    !['colorType', 'isIcon', 'isActive'].includes(prop.toString()),
})<{ colorType?: ButtonColorType; isIcon?: boolean; isActive?: boolean }>`
  ${buttonStyles};
  --button-background: ${({ colorType }) => getBackgroundColor(colorType)};
  --button-border: ${({ colorType }) => getBackgroundColor(colorType)};
  --button-hover-background: ${({ colorType }) =>
    getBackgroundHoverColor(colorType)};
  --button-hover-border: ${({ colorType }) =>
    getBackgroundHoverColor(colorType)};
  padding: ${({ isIcon }) => (isIcon ? '6px' : '13px 40px')};
  width: ${({ isIcon }) => (isIcon ? '42px' : 'auto')};
  aspect-ratio: ${({ isIcon }) => isIcon && '1 / 1'};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${({ isActive }) => isActive && buttonStylesHover}
`

const StyledLoader = styled(Loader)`
  width: 20px;
  height: 20px;
  border-color: currentColor;
  border-left-color: transparent;
`

export const ButtonText = styled('span', {
  shouldForwardProp: (prop) => !['withIcon'].includes(prop.toString()),
})<{ withIcon?: boolean }>`
  position: relative;
  z-index: 1;
  display: ${({ withIcon }) => withIcon && 'flex'};
  align-items: ${({ withIcon }) => withIcon && 'center'};
`

type NextLinkType = ComponentProps<typeof NextLink>
type CraftLinkType = ComponentProps<typeof CraftLink>
type Props = (
  | NextLinkType
  | CraftLinkType
  | ComponentProps<typeof BaseButton>
) & {
  /**
   * Should the button have a default brand color
   * or a different color type?
   */
  colorType?: ButtonColorType
  /**
   * Does the button have both text and an icon?
   */
  withIcon?: boolean
  /**
   * Does the button only have an icon?
   */
  isIcon?: boolean
  /**
   * Does the button have an active state?
   */
  isActive?: boolean
  /**
   * Does the button have a loading state?
   */
  isLoading?: boolean
}

const ButtonPrimary = (props: Props) => {
  if ('href' in props) {
    const { href, children, withIcon, isLoading, ...other } = props

    return (
      <StyledButton
        analyticsContext="buttonPrimary.nextLink"
        analyticsName={String(href)}
        as={() => (
          <StyledNextLink href={href} {...other}>
            {isLoading ? (
              <StyledLoader />
            ) : (
              <ButtonText withIcon={withIcon}>{children}</ButtonText>
            )}
          </StyledNextLink>
        )}
      >
        <ButtonText>{children}</ButtonText>
      </StyledButton>
    )
  }

  if ('craftLink' in props) {
    const { craftLink, children, withIcon, isLoading, ...other } = props

    return (
      <StyledButton
        analyticsContext="buttonPrimary.craftLink"
        analyticsName={String(craftLink.url)}
        as={() => (
          <StyledCraftLink craftLink={craftLink} {...other}>
            {isLoading ? (
              <StyledLoader />
            ) : (
              <ButtonText withIcon={withIcon}>{children}</ButtonText>
            )}
          </StyledCraftLink>
        )}
      >
        <ButtonText>{children}</ButtonText>
      </StyledButton>
    )
  }

  const { children, withIcon, isLoading, ...other } = props

  return (
    <StyledButton {...other}>
      {isLoading ? (
        <StyledLoader />
      ) : (
        <ButtonText withIcon={withIcon}>{children}</ButtonText>
      )}
    </StyledButton>
  )
}

export default ButtonPrimary
